<template>
  <Layout>
    <div class="clearfix"></div>
    <div class="card campign">
      <div class="card-body rounded-lg">
        <!-- Removed b-tabs and b-tab components -->
        <Table
          :status="0"
          :reload="reloadData"
          @reloaded="reloadData = false"
        />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import { campaignData } from "@/data/campaign";
import Table from "@/components/displaydevices/displaydevices-table";

export default {
  data() {
    return {
      campaignData: campaignData,
      reloadData: false,
    };
  },
  components: { Layout, Table },
  methods: {
    newCampaignAdded() {
      this.reloadData = true;
    },
  },
};
</script>

<style>
.campign .nav-tabs > li > a,
.nav-pills > li > a {
  color: rgb(85, 83, 83);
}
</style>
