<template>
  <div
    class="row"
    style="
      border: 1px solid #ced4d9;
      margin: 0;
      border-top: none;
      padding-bottom: 18px;
    "
  >
    <div class="col-12">
      <!-- <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          <b-form-input
            v-model="searchQuery"
            placeholder="Search..."
            @input="onSearch"
            class="mb-3"
          ></b-form-input>
        </div>
      </div> -->

      <!-- Table -->
      <div class="table-responsive mb-0">
        <b-table
          :items="displayData"
          :fields="fields"
          responsive="sm"
          :busy="isBusy"
          :per-page="perPage"
          tbody-tr-class="rowClass"
          @row-clicked="campignSelected"
          show-empty
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #empty>
            <p style="text-align: center; margin-top: 70px">
              No display devices
            </p>
          </template>

          <template #cell(serial_number)="row">
            {{ row.item.serial_number }} <br />
          </template>
          <template #cell(status)="row">
            <b-badge v-if="row.item.status === 1">Active</b-badge>
            <b-badge v-else>InActive</b-badge>

            <!-- <div
              class="badge font-size-12"
              :class="{
                'badge-soft-success': `${row.item.status}` === 'Paid',
                'badge-soft-warning': `${row.item.status}` === 'Unpaid',
              }"
            >
              {{ row.item.status }}
            </div> -->
          </template>
          <!-- <template #cell(status)="row">
            {{ row.item.status }}
          </template> -->
          <template #cell(createdAt)="row">
            {{
              new Date(row.item.createdAt).toLocaleString("en-US", {
                dateStyle: "short",
                timeStyle: "short",
              })
            }}
            <br />
          </template>
          <template #cell(updatedAt)="row">
            {{
              new Date(row.item.updatedAt).toLocaleString("en-US", {
                dateStyle: "short",
                timeStyle: "short",
              })
            }}
            <br />
          </template>
          <template #cell(last_online)="row">
            {{
              new Date(row.item.last_online).toLocaleString("en-US", {
                dateStyle: "short",
                timeStyle: "short",
              })
            }}
            <br />
          </template>
        </b-table>
        <div>
          <b-modal
            id="updatePriceModal"
            ref="updatePriceModal"
            title="Update Price"
            hide-footer
          >
            <b-form-group
              id="input-group-1"
              label="Enter Price:"
              label-for="input-1"
              description="The new price of the selected plan"
            >
              <!-- <b-form-input
                type="number"
                v-model="updatedPrice"
                placeholder="Please enter the price"
              >
              </b-form-input> -->
            </b-form-group>
            <!-- {{ this.selectedPlanPrice }} -->
            <!-- {{ this.selectedCampaign }} -->
            <!-- <div class="mt-2 text-center">
              <b-button @click="createDrivers">Update</b-button>
            </div> -->
          </b-modal>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                @change="updatePage"
              >
              </b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { display_device } from "@/config/api/display_device";

// import Swal from "sweetalert2";
export default {
  props: ["status", "reload"],
  data() {
    return {
      displayData: [],
      removeTms: false,
      isBusy: false,
      currentPage: this.$store.getters["displaydevice/currentPage"],
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      fields: [
        { key: "serial_number", sortable: false },
        { key: "status", sortable: false },
        { key: "createdAt", sortable: false },
        { key: "updatedAt", sortable: false },
        { key: "createdAt", sortable: false },
        { key: "last_online", sortable: false },
        // { key: "action", sortable: false },
      ],
      searchQuery: "",
    };
  },
  computed: {
    rows() {
      return this.$store.getters["displaydevice/rows"];
    },
  },

  created() {
    this.getAllDevices();
  },
  methods: {
    // updatePage(page) {
    //   this.$store.dispatch("campaign/changePage", page);
    //   this.getAllDevices();
    // },
    getAllDevices() {
      // this.isBusy = true;
      const api = display_device.list;
      api.params = {
        search: this.searchQuery,
      };
      this.generateAPI(api)
        .then((res) => {
          console.log("API Response: ", res); // Check the full response structure
          this.displayData = res.data.devices; // Ensure this path is correct
          this.isBusy = false;
          console.log("Display Data: ", this.displayData);
        })
        .catch((error) => {
          console.error("API call error: ", error);
        });
    },

    campignSelected(row) {
      // this.$store.dispatch("campaign/selectCampaign", row);
      this.$router.push({
        name: "displayDevicesDetails",
        params: { id: row._id },
      });
    },
    // async reloadData() {
    //   await this.$store.dispatch("campaign/changePage", 1);
    //   await this.getAllDevices();
    //   this.$emit("reloaded");
    // },
    // deleteCampaign(id) {
    //   Swal.fire({
    //     title: "Are you sure?",
    //     text: "You won't be able to revert this!",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#34c38f",
    //     cancelButtonColor: "#f46a6a",
    //     confirmButtonText: "Yes, delete it!",
    //   }).then((result) => {
    //     if (result.value) {
    //       this.deleteAction(id);
    //     }
    //   });
    // },
    // deleteAction(id) {
    //   const api = campaigns.deleteCampaign;
    //   api.id = id;
    //   this.generateAPI(api)
    //     .then(() => {
    //       if (this.displayData.length === 1) {
    //         const page =
    //           this.currentPage == 1
    //             ? this.currentPage
    //             : parseInt(this.currentPage) - 1;

    //         this.$store.dispatch("campaign/changePage", page);
    //         this.getAllDevices();
    //         this.currentPage = page;
    //       } else {
    //         this.getAllDevices();
    //       }

    //       Swal.fire("Deleted!", "Campaign has been deleted.", "success");
    //     })
    //     .catch((err) => {
    //       Swal.fire(
    //         "Error!",
    //         `Campaign is not deleted,${err.response.data.error}`,
    //         "error"
    //       );
    //     });
    // },

    // finishCampaign(id) {
    //   Swal.fire({
    //     title: "Are you sure?",
    //     text: "You won't be able to revert this!",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#34c38f",
    //     cancelButtonColor: "#f46a6a",
    //     confirmButtonText: "Finish",
    //     denyButtonText: "Finish + remove TMS",
    //     showDenyButton: this.status === 3 ? true : false,
    //     denyButtonColor: "#34c38f",
    //   }).then((result) => {
    //     if (result.value) {
    //       this.finishAction(id);
    //     } else if (result.isDenied) {
    //       console.log("deny");
    //       this.removeTms = true;
    //       console.log("removeTms", this.removeTms);
    //       this.finishAction(id);
    //     }
    //   });
    // },
    // finishAction(id) {
    //   console.log("finish with tms");
    //   const api = campaigns.finishCampaign;
    //   api.data = { campaign_id: id, removeTms: this.removeTms };
    //   this.generateAPI(api)
    //     .then(() => {
    //       if (this.displayData.length === 1) {
    //         const page =
    //           this.currentPage == 1
    //             ? this.currentPage
    //             : parseInt(this.currentPage) - 1;

    //         this.$store.dispatch("campaign/changePage", page);
    //         this.getAllDevices();
    //         this.currentPage = page;
    //       } else {
    //         this.getAllDevices();
    //       }

    //       Swal.fire("Finished!", "Campaign has been finished.", "success");
    //     })
    //     .catch((err) => {
    //       Swal.fire(
    //         "Error!",
    //         `Campaign is not finished,${err.response.data.error}`,
    //         "error"
    //       );
    //     });
    // },
    onSearch() {
      this.getAllDevices();
    },
  },
  watch: {
    reload(val) {
      if (val) {
        this.reloadData();
      }
    },
  },
};
</script>
<style>
.rowClass {
  cursor: pointer;
}

.rowClass:hover {
  background: #f2f2f2;
  /* color: #ffffff; */
}
</style>
